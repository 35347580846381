import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"

import Layout from "../components/layout/layout"

const BlogDetail = ({ data, location }) => {
  const blogData = data.blog

  var publishedDate = new Date(blogData.publishedDate.seconds * 1000)

  var options = { dateStyle: "long" }

  return (
    <Layout>
      <SEO
        title={blogData.title}
        description={blogData.blurb}
        imageUrl={blogData.detailImageUrl}
        url={location.href}
      />
      <div className="row column">
        <h1 className="headings">Blog</h1>
        <div className="row mb6">
          <div className="medium-9 columns blog-main-content">
            <div className="blog-inner">
              <div className="blog-item__image">
                <img src={blogData.imageUrl} alt={blogData.title} />
              </div>
              <div className="blog-details">
                <h2 className="headings xshort regular caps">
                  <strong>{blogData.title}</strong>
                </h2>
                <div className="blog-item__details">
                  <span className="blog-item__details-date">
                    {publishedDate.toLocaleDateString("en-US", options)}
                  </span>
                  <span className="blog-item__details-options">
                    <span className="blog-author">SSLLCMARKET </span>
                  </span>
                </div>
              </div>
              <div className="blog-content mb6 mt9">
                <div dangerouslySetInnerHTML={{ __html: blogData.body }}></div>
              </div>

              <hr />
            </div>
          </div>
          <div className="medium-3 columns blog-sidebar">
            {/* <div className="recent-posts">
              <div className="recent-posts--inner">
                <h4 className="headings xshort regular">Recent Posts</h4>

                <ul></ul>
              </div>
            </div> */}

            <div className="follow-us">
              <div className="follow-us--inner">
                <h4 className="headings xshort regular">Follow Us</h4>

                <div
                  className="social-links social-links--blog"
                  data-finder-type="Menu"
                  data-finder-name="Menu"
                  data-finder-id="1597215"
                >
                  <ul>
                    <li>
                      <a
                        target="_blank"
                        href="http://twitter.com/SurplusSolution"
                      >
                        <i className="icon-twitter"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        href="http://www.facebook.com/pages/Surplus-Solutions-LLC/265971573491744"
                      >
                        <i className="icon-facebook"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        href="http://www.linkedin.com/company/208136"
                      >
                        <i className="icon-linkedin"></i>
                      </a>
                    </li>

                    <li>
                      <a
                        target="_blank"
                        href="http://www.youtube.com/user/SurplusSolutionsLLC"
                      >
                        <i className="icon-youtube-play"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BlogDetail

export const query = graphql`
  query($slug: String!) {
    blog(slug: { eq: $slug }) {
      title
      body
      publishedDate {
        seconds
      }
      slug
      imageUrl
      excerpt
    }
  }
`
